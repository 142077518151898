import { createApp } from 'vue';
import { createPinia } from 'pinia';
import App from './App.vue';
import router from './router';
import "./styles/globals.css";

import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"

const Vue = createApp(App);
const State = createPinia();

Vue.use(router);
Vue.use(State);
Vue.mount('#app');
